import { FC, useCallback, useContext, useMemo } from "react";
import { Settings } from "react-slick";
import { CasinoGameTag, TranslationScopes } from "@finbackoffice/enums";
import Link from "next/link";
import {
    useTranslation,
    useRuntimeConfig,
    useSportsbookConfig,
    formatCasinoProviderName,
} from "@finbackoffice/site-core";
import {
    CasinoWidgetFolderEnum,
    formatAppNameText,
    SportsbookLayoutEnum,
} from "@finbackoffice/fe-core";
import { ICasinoGame } from "@finbackoffice/clientbff-client";
import { Svg } from "components/base/svg/Svg";
import Translate from "components/base/translate/Translate";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import SlickSlider from "components/base/slick-slider/SlickSlider";
import { CasinoGameItem } from "components/pages/casino/game-item/CasinoGameItem";
import { CasinoGamesContext } from "components/pages/casino/games/CasinoGames";
import { CasinoGameImgFolderType } from "utils/helpers";
import CasinoWidgetContainer from "../../casino-widget-container/CasinoWidgetContainer";
import styles from "./casino-games-row.module.sass";

export enum CasinoGamesRowVariantEnum {
    Horizontal = "horizontal",
    Narrow = "narrow",
}

type IProps = {
    variant: CasinoGamesRowVariantEnum;
    tag?: CasinoGameTag;
    headerIconFilename?: string;
};

const CasinoGamesRow: FC<IProps> = ({ tag, headerIconFilename, variant }) => {
    const { id, casinoGamesState, href, label, isSlots, imgSrc, imgFolder } =
        useContext(CasinoGamesContext);
    const { t: tWidgets } = useTranslation(TranslationScopes.CasinoWidgets);
    const { t } = useTranslation(TranslationScopes.Casino);
    const sportsbookConfig = useSportsbookConfig();
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const isFlatLayout = sportsbookConfig.type === SportsbookLayoutEnum.Flat;

    const sliderSettings: Settings = useMemo(
        () => ({
            dots: false,
            infinite: false,
            variableWidth: true,
            speed: 1000,
            slidesToShow: isFlatLayout ? 4 : 7,
            slidesToScroll: 2,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1916,
                    settings: {
                        slidesToShow: 6,
                    },
                },
                {
                    breakpoint: 1730,
                    settings: {
                        slidesToShow: 5,
                    },
                },
                {
                    breakpoint: 1546,
                    settings: {
                        slidesToShow: 4,
                    },
                },
                {
                    breakpoint: 1138,
                    settings: {
                        slidesToShow: 3,
                    },
                },
            ],
        }),
        [isFlatLayout],
    );

    const renderHeaderButton = useMemo(
        () =>
            href && (
                <Link href={href}>
                    <Translate tid="home_show_all" namespace={TranslationScopes.Home} />
                </Link>
            ),
        [href],
    );

    const renderHeader = useMemo(() => {
        const totalCount = casinoGamesState.totalCount;
        const titleKey = `casino_${id}_title`;
        const subTitleKey = `casino_${id}_sub_title`;
        const titleTranslationById = tWidgets(titleKey);
        const subTitleTranslationById = tWidgets(subTitleKey);
        const isSubTitleFallback =
            subTitleTranslationById === "" || subTitleTranslationById === subTitleKey;
        const isTitleFallback = titleTranslationById === titleKey;

        const showTitle =
            ((!isTitleFallback || !isSubTitleFallback) && id) ||
            ((isTitleFallback || isSubTitleFallback) && label);

        return (
            <>
                <h2 className={styles.headerSport}>
                    <Svg
                        src={`/${formatAppNameText(
                            COMMON_SITE_CONFIGS.appName,
                        )}/common/base-icons/${headerIconFilename}.svg`}
                        wrapper="span"
                        className="svg-icon"
                    />
                    {showTitle && id && !isTitleFallback
                        ? !!titleTranslationById.trim() && (
                              <Translate
                                  tid={`casino_${id}_title`}
                                  namespace={TranslationScopes.CasinoWidgets}
                              />
                          )
                        : label &&
                          typeof label !== "string" && (
                              <Translate
                                  tid={`${label?.title}`}
                                  namespace={TranslationScopes.Home}
                              />
                          )}
                </h2>
                <h2>
                    {id && !isSubTitleFallback ? (
                        <Translate
                            tid={`casino_${id}_sub_title`}
                            namespace={TranslationScopes.CasinoWidgets}
                            replace={{
                                app_name: COMMON_SITE_CONFIGS.appName,
                                tag: tag ? t(`casino_${tag?.toLowerCase()}`) : "",
                            }}
                        />
                    ) : (
                        label &&
                        typeof label !== "string" && (
                            <Translate
                                tid={`${label?.sub_title}`}
                                namespace={TranslationScopes.Home}
                                replace={{
                                    app_name: COMMON_SITE_CONFIGS.appName,
                                    tag: tag ? t(`casino_${tag?.toLowerCase()}`) : "",
                                }}
                            />
                        )
                    )}
                </h2>
                {!!totalCount && renderHeaderButton}
            </>
        );
    }, [
        COMMON_SITE_CONFIGS.appName,
        casinoGamesState.totalCount,
        headerIconFilename,
        id,
        label,
        renderHeaderButton,
        t,
        tWidgets,
        tag,
    ]);

    const containerVariant = useMemo(() => {
        switch (variant) {
            case CasinoGamesRowVariantEnum.Horizontal:
                return "rowHorizontal";
            case CasinoGamesRowVariantEnum.Narrow:
                return "narrowRow";
            default:
                return undefined;
        }
    }, [variant]);

    const getImgSrcFolder = useCallback(
        (game: ICasinoGame): CasinoGameImgFolderType | undefined => {
            if (variant === CasinoGamesRowVariantEnum.Narrow) {
                return "narrow";
            } else if (imgFolder && imgFolder === CasinoWidgetFolderEnum.Central) {
                return `${imgFolder}/${formatCasinoProviderName(game.category)}`;
            }

            return undefined;
        },
        [imgFolder, variant],
    );

    /* const getFallbackSrc = useCallback(
        (game: ICasinoGame) => {
            if (variant === CasinoGamesRowVariantEnum.Narrow) {
                return game.image_portrait;
            }

            return undefined;
        },
        [variant],
    ); */

    const renderGame = useCallback(
        (game: ICasinoGame) => (
            <CasinoGameItem
                key={game.id}
                game={game}
                hidePlayButtons={!isSlots}
                // fallbackSrc={getFallbackSrc(game)}
                imgSrc={imgSrc}
                imgFolder={getImgSrcFolder(game)}
            />
        ),
        [getImgSrcFolder, imgSrc, isSlots],
    );

    return (
        <CasinoWidgetContainer
            variant={containerVariant}
            renderHeader={renderHeader}
            loading={casinoGamesState.loading}>
            {casinoGamesState.games.length !== 0 && (
                <FadeInAnimation>
                    <div className={styles.casinoRowGames}>
                        <div>
                            <SlickSlider settings={sliderSettings}>
                                {casinoGamesState.games.map(renderGame)}
                            </SlickSlider>
                        </div>
                    </div>
                </FadeInAnimation>
            )}
        </CasinoWidgetContainer>
    );
};

export default CasinoGamesRow;
