import { FC, useCallback, useContext, useMemo } from "react";
import { Settings } from "react-slick";
import {
    BannersContext,
    CasinoScopesMap,
    FeedContext,
    UseCasinoGamesHookOptions,
    useRuntimeConfig,
    useSiteConfig,
    useTranslation,
} from "@finbackoffice/site-core";
import {
    HomePageSectionsEnum,
    ILayoutsHomePageSection,
    ISiteConfigLayouts,
} from "@finbackoffice/fe-core";
import ScrollComponent from "components/base/scroll/ScrollComponent";
import BannersSlider from "components/base/banners-slider/BannersSlider";
import Footer from "components/footer/Footer";
import { Svg } from "components/base/svg/Svg";
import { RouteNames } from "utils/constants";
import CasinoGames from "../casino/games/CasinoGames";
import styles from "./home.module.sass";
import HomeTournamentItem from "./tournament/HomeTournamentItem";
import HomeFAQ from "./faq/HomeFAQ";
import SportGames from "./sport-games/SportGames";

export interface IHomeTournamentData {
    id: number;
    sportSlug: string;
    categoryId: number;
}

const Home: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const siteLayoutsConfig = useSiteConfig<ISiteConfigLayouts>("layouts");
    const { t } = useTranslation();
    const homePageConfig = siteLayoutsConfig.homePage;
    const { banners } = useContext(BannersContext);
    const { prematchTopLeagues, hasPrematchSports } = useContext(FeedContext);
    const hasTopLeagues = prematchTopLeagues.length > 0;
    const ready = hasPrematchSports && hasTopLeagues;

    const bannerSettings: Settings = useMemo(
        () => ({
            slidesToScroll: 1,
        }),
        [],
    );

    const renderSection = useCallback(
        (section: ILayoutsHomePageSection, index: number) => {
            if (section.enable) {
                switch (section.name) {
                    case HomePageSectionsEnum.Banners:
                        return (
                            <BannersSlider
                                key={index}
                                banners={banners}
                                sliderSettings={bannerSettings}
                                wrapperClassName={styles.homeBannerContainer}
                                imgProps={{
                                    width: 1312,
                                    height: 300,
                                }}
                            />
                        );
                    case HomePageSectionsEnum.Slots:
                    case HomePageSectionsEnum.LiveCasino:
                        const gameTypes = section.type || CasinoScopesMap[section.name];
                        const commonOptions: UseCasinoGamesHookOptions = {
                            megaways: section.prop === "megaways",
                            bonusbuy: section.prop === "bonusbuy",
                            has_jackpot: section.prop === "has_jackpot",
                        };

                        if (section.search) {
                            commonOptions.gameName = section.search;
                        }

                        return (
                            <CasinoGames
                                id={section.id}
                                key={index}
                                style={section.style}
                                tag={section.tag}
                                gameTypes={gameTypes}
                                provider={section.provider || "All"}
                                options={{
                                    ...commonOptions,
                                }}
                                href={section.href}
                                imgSrc={section.imgSrc}
                                imgFolder={COMMON_SITE_CONFIGS.casinoImg}
                            />
                        );
                    case HomePageSectionsEnum.Sport:
                        if (section.top_leagues_index && ready) {
                            return section.top_leagues_index.map((leagueIndex) => {
                                const tournament = prematchTopLeagues[leagueIndex - 1];
                                return tournament ? (
                                    <HomeTournamentItem
                                        key={tournament.id}
                                        tournament={tournament}
                                    />
                                ) : null;
                            });
                        }
                        break;
                    case HomePageSectionsEnum.PrematchTop:
                        return (
                            <SportGames
                                key={index}
                                icon={
                                    <Svg
                                        src="/common/desktop/base-icons/top-leagues.svg"
                                        wrapper="span"
                                        className="svg-icon"
                                    />
                                }
                                title={t("sidebar_prematch_top_matches")}
                                href={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}/${RouteNames.TOP}`}
                                type={section.name}
                                hoursRange={section.timeRange}
                                sliderSpeed={section.sliderSpeed}
                            />
                        );
                    case HomePageSectionsEnum.LiveUpcoming:
                        return (
                            <SportGames
                                key={index}
                                icon={
                                    <Svg
                                        src="/common/desktop/base-icons/upcoming-matches.svg"
                                        wrapper="span"
                                        className="svg-icon"
                                    />
                                }
                                title={t("sidebar_prematch_upcoming_live")}
                                href={`/${RouteNames.SPORT}/${RouteNames.UPCOMING}`}
                                type={section.name}
                                hoursRange={section.timeRange}
                                sliderSpeed={section.sliderSpeed}
                            />
                        );
                    default:
                        return null;
                }
            }
            return null;
        },
        [COMMON_SITE_CONFIGS.casinoImg, bannerSettings, banners, prematchTopLeagues, ready, t],
    );

    return (
        <ScrollComponent showScrollBar>
            <div className={styles.middleAreaHomePage}>
                {homePageConfig.widgets.map(renderSection)}
            </div>
            {homePageConfig.enableFAQ && <HomeFAQ />}
            <Footer />
            {/* <div className={styles.homePageItem}>
                        <div className={styles.header}>
                            <span className={styles.headerSport}>
                                <Svg
                                loader
                                    src="/common/sport-icons/1.svg"
                                    wrapper="span"
                                    className="svg-sport-icon"
                                />
                                Soccer
                            </span>
                            <ActiveLink
                                href={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}`}
                                activeClassName={styles.active}
                                includes={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}`}>
                                <a>Top Events</a>
                            </ActiveLink>
                            <nav className={styles.carouselBtns}>
                                <li className={classNames(styles.arrowLeft, styles.disabled)} />
                                <li className={styles.arrowRight} />
                            </nav>
                        </div>

                        <div className={styles.homeGames}>
                            <div>
                                live soccer
                                <div className={styles.homeGameItem}>
                                    <div className={styles.homeGameItemHeader}>
                                        <div>
                                            <Svg
                                                src="/common/regions/30.svg"
                                                wrapper="span"
                                                className="svg-region-icon"
                                            />
                                            Germany / Bundesliga
                                        </div>
                                        <span className={styles.liveGameLabel}>Live</span>
                                    </div>
                                    <div className={classNames(styles.soccer, styles.liveSoccer)}>
                                        <div className={styles.soccerTeam}>
                                            <TeamIcon sportId={1} teamId={22} />
                                            <span>Borussia Dortmund</span>
                                        </div>
                                        <div className={styles.soccerScore}>
                                            <span>
                                                <span>0</span>:<span>1</span>
                                            </span>
                                            Halftime
                                        </div>
                                        <div className={styles.soccerTeam}>
                                            <TeamIcon sportId={1} teamId={31} />
                                            <span>Eintracht Francfurt</span>
                                        </div>
                                    </div>
                                    <div className={styles.homeGameItemMarket}>outcome</div>
                                </div>
                                prematch soccer
                                <div className={styles.homeGameItem}>
                                    <div className={styles.homeGameItemHeader}>
                                        <div>
                                            <Svg
                                                src="/common/regions/30.svg"
                                                wrapper="span"
                                                className="svg-region-icon"
                                            />
                                            Germany / Bundesliga
                                        </div>
                                    </div>
                                    <div className={styles.soccer}>
                                        <div className={styles.soccerTeam}>
                                            <TeamIcon sportId={1} teamId={22} />
                                            <span>Borussia Dortmund</span>
                                        </div>
                                        <div className={styles.soccerScore}>
                                            <span>22:08.22</span>
                                            23:30
                                        </div>
                                        <div className={styles.soccerTeam}>
                                            <TeamIcon sportId={1} teamId={31} />
                                            <span>Eintracht Francfurt</span>
                                        </div>
                                    </div>
                                    <div className={styles.homeGameItemMarket}>outcome</div>
                                </div>
                                <div className={styles.homeGameItem}>
                                    <div className={styles.homeGameItemHeader}>
                                        <div>
                                            <Svg
                                                src="/common/regions/30.svg"
                                                wrapper="span"
                                                className="svg-region-icon"
                                            />
                                            Germany / Bundesliga
                                        </div>
                                    </div>
                                    <div className={styles.soccer}>
                                        <div className={styles.soccerTeam}>
                                            <TeamIcon sportId={1} teamId={22} />
                                            <span>Borussia Dortmund</span>
                                        </div>
                                        <div className={styles.soccerScore}>
                                            <span>22:08.22</span>
                                            23:30
                                        </div>
                                        <div className={styles.soccerTeam}>
                                            <TeamIcon sportId={1} teamId={31} />
                                            <span>Eintracht Francfurt</span>
                                        </div>
                                    </div>
                                    <div className={styles.homeGameItemMarket}>outcome</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.homePageItem}>
                        <div className={styles.header}>
                            <span className={styles.headerSport}>
                                <Svg
                                    src="/common/sport-icons/2.svg"
                                    wrapper="span"
                                    className="svg-sport-icon"
                                />
                                Basketball
                            </span>
                            <ActiveLink
                                href={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}`}
                                activeClassName={styles.active}
                                includes={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}`}>
                                <a>Top Events</a>
                            </ActiveLink>
                            <nav className={styles.carouselBtns}>
                                <li className={classNames(styles.arrowLeft, styles.disabled)} />
                                <li className={styles.arrowRight} />
                            </nav>
                        </div>
                        <div className={styles.homeGames}>
                            <div>
                                live basketball
                                <div className={styles.homeGameItem}>
                                    <div className={styles.homeGameItemHeader}>
                                        <div>
                                            <Svg
                                                src="/common/regions/26.svg"
                                                wrapper="span"
                                                className="svg-region-icon"
                                            />
                                            USA / NBA
                                        </div>
                                        <span className={styles.liveGameLabel}>Live</span>
                                    </div>
                                    <div className={styles.game}>
                                        <div className={styles.gameHeader}>
                                            <div>Set 2</div>
                                            <span>1</span>
                                            <span className={styles.check}>2</span>
                                            <span>T</span>
                                        </div>
                                        <div className={styles.team}>
                                            <TeamIcon sportId={2} teamId={448391} />
                                            <i className={classNames(styles.inning, styles.active)} />
                                            <span className={styles.teamName}>
                                                Marikina Shoemasters
                                            </span>
                                            <span className={classNames(styles.score, styles.check)}>
                                                26
                                            </span>
                                            <span className={styles.score}>10</span>
                                            <span className={styles.total}>
                                                <span>46</span>
                                            </span>
                                        </div>
                                        <div className={styles.team}>
                                            <TeamIcon sportId={2} teamId={448765} />
                                            <i className={styles.inning} />
                                            <span className={styles.teamName}>
                                                San Juan Knights
                                            </span>
                                            <span className={styles.score}>10</span>
                                            <span className={styles.score}>16</span>
                                            <span className={styles.total}>
                                                <span>26</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className={styles.homeGameItemMarket}>outcome</div>
                                </div>
                                prematch basketball
                                <div className={styles.homeGameItem}>
                                    <div className={styles.homeGameItemHeader}>
                                        <div>
                                            <Svg
                                                src="/common/regions/26.svg"
                                                wrapper="span"
                                                className="svg-region-icon"
                                            />
                                            USA / NBA
                                        </div>
                                    </div>
                                    <div className={styles.game}>
                                        <div className={styles.gameHeader}>
                                            <div>22.08.22 23:00</div>
                                        </div>
                                        <div className={styles.team}>
                                            <TeamIcon sportId={2} teamId={448391} />
                                            <span className={styles.teamName}>
                                                Marikina Shoemasters
                                            </span>
                                        </div>
                                        <div className={styles.team}>
                                            <TeamIcon sportId={2} teamId={448765} />
                                            <span className={styles.teamName}>
                                                San Juan Knights
                                            </span>
                                        </div>
                                    </div>
                                    <div className={styles.homeGameItemMarket}>outcome</div>
                                </div>
                            </div>
                        </div>
                    </div> */}
        </ScrollComponent>
    );
};

export default Home;
